<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="blue"
      >
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ form.name }} - Excel导入</v-toolbar-title>
      </v-toolbar>
      <v-container class="mt-4">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step
              :complete="step > 1"
              step="1"
            >
              上传 Excel
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="step > 2"
              step="2"
            >
              导入数据
            </v-stepper-step>

            <v-divider />

            <v-stepper-step step="3">
              完成
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  offset-md="3"
                  class="text-center"
                >
                  <v-file-input
                    v-model="excel"
                    label="上传excel文件"
                    placeholder="选择文件"
                    outlined
                    autofocus
                    show-size
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    prepend-icon="mdi-microsoft-excel"
                  />

                  <v-btn
                    color="primary"
                    large
                    :disabled="!excel"
                    @click="readExcel"
                  >
                    <v-icon left>
                      mdi-cloud-upload
                    </v-icon>
                    上传文件
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  offset-md="3"
                  class="text-center mt-2"
                >
                  <v-btn
                    link
                    @click="downloadTemplate"
                  >
                    下载导入模板
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="15"
              />

              <v-btn
                color="primary"
                @click="step = 3"
              >
                下一步
              </v-btn>

              <v-btn
                text
                @click="step = 1"
              >
                返回
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  offset-md="3"
                  class="text-center"
                >
                  <v-btn
                    color="primary"
                    large
                    :loading="importing"
                    @click="upload"
                  >
                    <v-icon left>
                      mdi-cloud-upload
                    </v-icon>
                    导入
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  offset-md="3"
                  class="text-center mt-2"
                >
                  <v-btn
                    text
                    @click="step = 2"
                  >
                    返回
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import http from '../../../api';
  import file from '../../../utils/file'
  import { orderBy } from 'lodash';

  export default {
    data: () => ({
      dialog: false,
      uploading: false,
      importing: false,

      excel: null,
      form: {},
      step: 1,
      fields: [],
      headers: [],
      items: [],
    }),

    methods: {
      open: function (form) {
        this.form = form;
        this.dialog = true;
        this.items = [];
        this.fields = [];
        this.excel = null;
        this.step = 1;
        // load form fields
        this.loadForm();
      },

      readExcel: async function () {
        // 上传
        // upload excel
        this.uploading = true;
        const resp = await http.file.uploadExcel(this.excel);
        if (resp.data.code !== 200) {
          this.uploading = false;
          this.$swal({
            title: resp.data.msg,
            icon: 'error',
          });
          return;
        }
        const rows = resp.data.data;
        const items = [];
        rows.forEach((row) => {
          const item = {};
          this.fields.forEach((field, i) => {
            item[field.code] = row[i];
          });
          items.push(item);
        });
        this.items = items;
        this.step = 2;
      },

      upload: async function () {
        // 上传
        // upload excel
        this.importing = true;
        const resp = await http.form.importForm(this.form.id, this.excel);
        this.importing = false;
        if (resp.data.code !== 200) {
          this.$swal({
            title: resp.data.msg,
            icon: 'error',
          });
          return;
        }
        this.$swal({
          title: `已成功导入 ${resp.data.data} 条`,
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
        this.step = 1;
        this.dialog = false;
      },

      loadForm: async function () {
        const resp = await this.$axios.get(`forms/${this.form.id}/fields`);
        if (resp.data.code !== 200) {
          this.$swal({
            title: resp.data.msg,
            icon: 'error',
          });
          return;
        }
        this.fields = orderBy(resp.data.data, ['weight'], ['desc']);
        const headers = [];
        this.fields.forEach((field) => {
          headers.push({
            value: field.code,
            text: field.name,
            sortable: false,
          });
        });
        this.headers = headers;
      },

      downloadTemplate: async function () {
        http.form.exportTemplates(this.form.id)
          .then(res => {
            console.log(res, res.data);
            file.downFile(res.data, this.form.name);
          });
      },
    },
  };
</script>
